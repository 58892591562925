<template>
  <div class="signed">
    <TosbStep class="step pa-16 pb-0" :steps="steps" :current="step" />

    <div class="signed-header">
      点击“确认签署”即代表您已同意以下合同内容:
    </div>
    <a href=""><Icon name="circle" />《运输合作协议》</a>
    <a href=""><Icon name="circle" />《税务代理委托书》</a>
    <Button @click="handleSigned" size="large" type="info">
      确认签署
    </Button>
  </div>
</template>
<script>
import { Icon, Button } from 'vant';
import TosbStep from '@/components/Step/index.vue';
import { useRefs } from '@/utils/compsitionHack';
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'Singed',
  components: {
    Icon,
    Button,
    TosbStep
  },
  setup() {
    const refs = useRefs();
    const step = ref(0);
    const handleSigned = () => {
      refs.RefStep.handleNextStep();
    };
    const steps = ['身份证', '人脸拍照', '认证结果'];

    return {
      steps,
      step,
      handleSigned
    };
  }
});
</script>
<style lang="less" scoped>
.signed {
  font-size: 0.16rem;
  padding: 0.16rem 0.22rem;
  text-align: left;
  a {
    display: block;
    line-height: 0.23rem;
  }
  .btn-cover {
    margin-top: 1.2rem;
  }
}
</style>
