<template>
  <div class="tosb-step flex justify-center items-center">
    <div
      class="flex tosb-step-item justify-center relative"
      v-for="(step, index) in steps"
      :key="index"
      :class="[`tosb-step-item-${current > index ? 'finish' : current == index ? 'editing' : 'waitting'}`]"
    >
      <div class="flex flex-col justify-center items-center">
        <div class="relative">
          <div class="point">
            <Icon v-if="current > index" name="icon_tianxiewancheng"></Icon>
            <Icon v-else-if="current == index" name="icon_tianxiehzhong"></Icon
            ><Icon v-else name="icon_weitianxie"></Icon>
          </div>
        </div>
        <div class="mt-8 text-14" :class="[`${index > current ? 'text-disable' : 'text-primary'}`]">
          {{ step }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'TosbStep',
  props: {
    current: {
      type: Number,
      default: -1
    },
    steps: {
      type: Array,
      default: () => []
    }
  }
});
</script>

<style lang="less">
.tosb-step {
  .line {
    width: 0.8rem;
    height: 0.01rem;
    background-color: #0076ff;
    opacity: 1;
  }

  &-item {
    width: 1rem;

    .point {
      width: 0.16rem;
      height: 0.16rem;
      border-radius: 50%;
    }
  }

  &-item:not(:last-child)::after {
    position: absolute;
    content: '';
    top: 0.08rem;
    height: 0.005rem;
    background-color: #d4d4d4;
    right: 0;
    left: calc(50% + 0.24rem);
  }

  &-item:not(:first-child)::before {
    position: absolute;
    content: '';
    top: 0.08rem;
    height: 0.005rem;
    background-color: #d4d4d4;
    width: calc(50% - 0.24rem);
    left: 0;
  }

  &-item-finish:not(:last-child)::after {
    background-color: #0076ff;
  }

  &-item-finish:not(:first-child)::before {
    background-color: #0076ff;
  }

  &-item-editing:not(:first-child)::before {
    background-color: #0076ff;
  }
}
</style>
